import React, { useState, useEffect } from "react";
import * as dateFns from "date-fns";
import Modal from "../../common/Modal";
import Button from "../../common/FormFields/Button";
import Textarea from "../../common/FormFields/Textarea";
import Checkbox from "../../common/FormFields/Checkbox";
import CustomSelect from "../../common/FormFields/Select";
import Datepicker from "../../common/FormFields/Datepicker";
import CustomScrollbar from "../../common/CustomScrollbar";
import Loader from "../../common/Loader";
import {
  ABO,
  DONOR_STATUS_DETAILS,
  Rhd,
  APPOINTMENT_TYPE_DETAILS,
  BLOOD_TITER
} from "../../Configuration/Modals/constants";
import { useTranslation } from "react-i18next";

const DonorDetails = (props) => {
  const {
    updateDonor,
    deleteDonorDetails,
    toggleDonorDetailsModal,
    donorDetailsData,
    isUserPending,
    isModalOpen,
    userRoleName,
    bloodbankId
  } = props;
  const { currentUser, currentDetails } = donorDetailsData
    ? donorDetailsData
    : {};
  const { t } = useTranslation();
  const donorStatusDetails = DONOR_STATUS_DETAILS.map((donorStatus) => ({
    ...donorStatus,
    label: t(`common.donorStatus.${donorStatus.label}`)
  }));
  const appointmentTypeDetails = APPOINTMENT_TYPE_DETAILS.map((donorType) => ({
    ...donorType,
    label: t(`common.donorType.${donorType.label}`)
  }));
  const bloodTiterDetails = BLOOD_TITER.map((titer) => ({
    value: titer,
    label: t(`configuration.settings.titer.${titer}`)
  }));

  const [currentBloodTiter, setCurrentBloodTiter] = useState("");
  const [currentBloodType, setCurrentBloodType] = useState("");
  const [currentQuarantine, setCurrentQuarantine] = useState("");
  const [currentQuarantineExpire, setCurrentQuarantineExpire] = useState("");
  const [currentRhesus, setCurrentRhesus] = useState("");
  const [currentDonorStatus, setCurrentDonorStatus] = useState("");
  const [currentAppType, setCurrentAppType] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [currentLastDonation, setCurrentLastDonation] = useState("");
  const [currentNextAppointment, setCurrentNextAppointment] = useState("");
  const [isEditable, setEditable] = useState(false);
  const [registrationDate, setRegistrationDate] = useState("");
  const [isNewRegistration, setIsNewRegistration] = useState(false);
  const [isAppUser, setIsAppUser] = useState(false);
  const [isSms, setIsSms] = useState(false);

  useEffect(() => {
    const bloodTiter =
      currentUser !== undefined &&
        currentUser.bloodTiter !== undefined &&
        currentUser.bloodTiter !== null
        ? currentUser.bloodTiter
        : BLOOD_TITER[2];
    const type = currentUser !== undefined ? currentUser.type : "";
    const status = currentUser !== undefined ? currentUser.status : "";
    const rhesus =
      currentUser !== undefined &&
        currentUser.donorType !== undefined &&
        currentUser.donorType !== null
        ? currentUser.donorType.rhesus
        : "";
    const bloodtype =
      currentUser !== undefined &&
        currentUser.donorType !== undefined &&
        currentUser.donorType !== null
        ? currentUser.donorType.bloodType
        : "";
    const entryInBecs =
      currentUser !== undefined ? currentUser.registeredInBecs : false;
    let quarantineDesc =
      currentUser !== undefined &&
        currentUser.quarantineDesc !== undefined &&
        currentUser.quarantineDesc !== null
        ? currentUser.quarantineDesc
        : "";
    let quarantine =
      currentUser !== undefined &&
        currentUser.quarantine !== undefined &&
        currentUser.quarantine !== null &&
        typeof currentUser.lastDonation === "number"
        ? new Date(currentUser.quarantine)
        : "";
    let lastDonation =
      currentUser !== undefined &&
        currentUser.lastDonation !== undefined &&
        currentUser.lastDonation !== null &&
        typeof currentUser.lastDonation === "number"
        ? new Date(currentUser.lastDonation)
        : "";
    const statusRes = currentDetails ? currentDetails.status : "";
    let nextAppointment =
      currentUser !== undefined &&
        currentUser.nextAppointment !== undefined &&
        currentUser.nextAppointment !== null
        ? currentUser.nextAppointment
        : "";
    const regDate = currentUser !== undefined ? currentUser.registered : "";
    const appUser = currentUser !== undefined && !!currentUser.fcmDeviceId;
    const sms = currentUser !== undefined ? currentUser.sms : false;

    setCurrentBloodTiter(
      status === "new_registration" ? BLOOD_TITER[2] : bloodTiter
    );
    setCurrentAppType(type === "" ? APPOINTMENT_TYPE_DETAILS[0].value : type);
    setCurrentDonorStatus(
      status === "new_registration" ? DONOR_STATUS_DETAILS[0].value : status
    );
    setCurrentRhesus(rhesus);
    setCurrentBloodType(bloodtype);
    setChecked(entryInBecs);
    setCurrentNextAppointment(nextAppointment);
    setCurrentQuarantine(quarantineDesc);
    setCurrentQuarantineExpire(quarantine);
    setCurrentLastDonation(lastDonation);
    setEditable(
      nextAppointment === "" ||
      statusRes === "did_attend" ||
      statusRes === "did_not_attend" ||
      status === "new_registration"
    );
    setRegistrationDate(Date.parse(regDate));
    setIsNewRegistration(status === "new_registration");
    setIsAppUser(appUser);
    setIsSms(sms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const changeBloodType = (data) => {
    setCurrentBloodType(data.value);
  };

  const changeBloodTiter = (data) => {
    setCurrentBloodTiter(data.value);
  };

  const changeAppType = (data) => {
    setCurrentAppType(data.value);
  };

  const changeDonorStatus = (data) => {
    setCurrentDonorStatus(data.value);
    setCurrentAppType(APPOINTMENT_TYPE_DETAILS[0].value);
    if (data.value === DONOR_STATUS_DETAILS[0].value) {
      setCurrentBloodType("");
      setCurrentRhesus("");
    }
  };
  
  const changeRhesus = (data) => {
    setCurrentRhesus(data.value);
  };

  const changeQuarantine = (data) => {
    setCurrentQuarantine(data);
  };

  const changeQuarantineExpire = (data) => {
    setCurrentQuarantineExpire(data);
  };

  const changeLastDonation = (data) => {
    setCurrentLastDonation(data);
  };

  const checkboxHandler = () => {
    setChecked(!isChecked);
  };

  const smsCheckboxHandler = () => {
    setIsSms(!isSms);
  };

  const handleClose = () => {
    toggleDonorDetailsModal();
    deleteDonorDetails();
  };

  const formatText = (text) => {
    const firstPart = text.slice(0, 6);
    const secondPart = text.slice(6, 11);
    return `${firstPart}\u00A0${secondPart}`;
  };

  const handleCopy = (e) => {
    e.clipboardData.setData("text/plain", currentUser.pnr);
    e.preventDefault();
  };

  return (
    <Modal
      additionalClassName="large"
      isModalOpen={isModalOpen}
      handleModalClose={handleClose}
      {...props}
    >
      <div className="modal-header">
        <h2 className="modal-title">{t("donorDetails.header")}</h2>
        <button className="modal-close" onClick={handleClose}>
          <span className="icon-close" />
        </button>
      </div>
      {false ? (
        <Loader />
      ) : (
        <CustomScrollbar className="modal-body">
          <section className="modal-content">
            <h3 className="modal-subtitle">
              {currentUser === undefined ||
                  currentUser.firstname === undefined ||
                  currentUser.lastname === undefined
                ? null
                : `${currentUser.firstname} ${currentUser.lastname}`}
            </h3>
            <div className="details">
              <ul className="details-list">
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.idNumber")}
                  </div>
                  <div className="details-value" onCopy={handleCopy}>
                    {currentUser ? formatText(currentUser.pnr) : null}
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">{t("common.bloodType")}</div>
                  <div className="details-value">
                    <div className="inputs-group">
                      <div className="inputs-group-item">ABO</div>
                      <div className="inputs-group-item">
                        <CustomSelect
                          data={ABO}
                          placeholder=" "
                          additionalClassName="xs"
                          isDisabled={
                            isUserPending ||
                            !isEditable ||
                            currentDonorStatus === "" ||
                            currentDonorStatus === DONOR_STATUS_DETAILS[0].value
                          }
                          onChange={changeBloodType}
                          value={ABO.find(
                            (item) => item.value === currentBloodType
                          )}
                        />
                      </div>
                      <div className="inputs-group-item">Rh(D)</div>
                      <div className="inputs-group-item">
                        <CustomSelect
                          data={Rhd}
                          placeholder=" "
                          additionalClassName="xs"
                          isDisabled={
                            isUserPending ||
                            !isEditable ||
                            currentDonorStatus === "" ||
                            currentDonorStatus === DONOR_STATUS_DETAILS[0].value
                          }
                          onChange={changeRhesus}
                          value={Rhd.find(
                            (item) => item.value === currentRhesus
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("configuration.settings.titer.title")}
                  </div>
                  <div className="details-value">
                    <CustomSelect
                      data={bloodTiterDetails}
                      placeholder=" "
                      isDisabled={
                        isUserPending ||
                        !isEditable ||
                        currentDonorStatus === "" ||
                        currentDonorStatus === DONOR_STATUS_DETAILS[0].value
                      }
                      onChange={changeBloodTiter}
                      value={bloodTiterDetails.find(
                        (item) => item.value === currentBloodTiter
                      )}
                    />
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("common.donorStatus.header")}
                  </div>
                  <div className="details-value">
                    <CustomSelect
                      data={donorStatusDetails}
                      isDisabled={isUserPending || !isEditable}
                      onChange={changeDonorStatus}
                      value={donorStatusDetails.find(
                        (item) => item.value === currentDonorStatus
                      )}
                    />
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("common.donorType.header")}
                  </div>
                  <div className="details-value">
                    <CustomSelect
                      isDisabled={
                        isUserPending ||
                        !isEditable ||
                        currentDonorStatus === "" ||
                        currentDonorStatus === DONOR_STATUS_DETAILS[0].value ||
                        currentDonorStatus === DONOR_STATUS_DETAILS[1].value
                      }
                      data={appointmentTypeDetails}
                      onChange={changeAppType}
                      value={appointmentTypeDetails.find(
                        (item) => item.value === currentAppType
                      )}
                    />
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.lastDraw")}
                  </div>
                  {currentLastDonation || registrationDate ? (
                    <div className="details-value d-flex">
                      <div className="schedule-control">
                        <Datepicker
                          disabled={
                            !isEditable ||
                            currentDonorStatus === "" ||
                            currentDonorStatus === DONOR_STATUS_DETAILS[0].value
                          }
                          date={currentLastDonation || registrationDate}
                          onDateChange={changeLastDonation}
                          maxDate={dateFns.startOfDay(new Date())}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.quarantine")}
                  </div>
                  <div className="details-value">
                    <Textarea
                      disabled={
                        isUserPending ||
                        !isEditable ||
                        currentDonorStatus === "" ||
                        currentDonorStatus === DONOR_STATUS_DETAILS[0].value
                      }
                      changeValue={changeQuarantine}
                      placeholder={
                        currentQuarantine || t("donorDetails.description")
                      }
                    />
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.quarantineExpires")}
                  </div>
                  {currentQuarantineExpire || registrationDate ? (
                    <div className="details-value d-flex">
                      <div className="schedule-control">
                        <Datepicker
                          disabled={
                            !isEditable ||
                            currentDonorStatus === "" ||
                            currentDonorStatus === DONOR_STATUS_DETAILS[0].value
                          }
                          date={currentQuarantineExpire || registrationDate}
                          onDateChange={changeQuarantineExpire}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.nextAppointment")}
                  </div>
                  <div className="details-value">
                    <div className="inputs-group">
                      <div className="inputs-group-item">
                          {currentUser !== undefined &&
                            (currentUser.status !== "new" ||
                              currentUser.status !== "new_registration") &&
                            currentNextAppointment !== "" && currentNextAppointment.startDate && currentNextAppointment.endDate
                          ? `${dateFns.format(
                            new Date(currentNextAppointment.startDate),
                            "EEEE"
                          )} ${dateFns.format(
                            new Date(currentNextAppointment.startDate),
                            "d"
                          )} 
                          ${dateFns.format(
                            new Date(currentNextAppointment.startDate),
                            "LLLL"
                          )} ${dateFns.format(
                            new Date(currentNextAppointment.startDate),
                            "yyyy"
                          )}`
                          : ""}
                      </div>
                      <div className="inputs-group-item">
                        {currentUser !== undefined &&
                            (currentUser.status !== "new" ||
                              currentUser.status !== "new_registration") &&
                            currentNextAppointment !== "" && currentNextAppointment.startDate && currentNextAppointment.endDate
                          ? `${dateFns.format(
                            new Date(currentNextAppointment.startDate),
                            "H"
                          )}:${dateFns.format(
                            new Date(currentNextAppointment.startDate),
                            "mm"
                          )} - 
                          ${dateFns.format(
                            new Date(currentNextAppointment.endDate),
                            "H"
                          )}:${dateFns.format(
                            new Date(currentNextAppointment.endDate),
                            "mm"
                          )} `
                          : ""}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          <section className="modal-content light">
            <h3 className="modal-subtitle">
              {t("donorDetails.donorContacts")}
            </h3>
            <div className="details">
              <ul className="details-list">
              <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.donationSite")}
                  </div>
                  <div className="details-value">
                    {currentUser === undefined ||
                        currentUser.donationSite === undefined
                      ? null
                      : currentUser.donationSite}
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.address")}
                  </div>
                  <div className="details-value">
                    {currentUser === undefined ||
                        currentUser.address === undefined
                      ? null
                      : currentUser.address.street}
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">{t("donorDetails.city")}</div>
                  <div className="details-value">
                    {currentUser === undefined ||
                        currentUser.address === undefined
                      ? null
                      : currentUser.address.city}
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">
                    {t("donorDetails.postalCode")}
                  </div>
                  <div className="details-value">
                    {currentUser === undefined ||
                        currentUser.address === undefined
                      ? null
                      : currentUser.address.zip}
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label">{t("donorDetails.phone")}</div>
                  <div className="details-value">
                    {currentUser === undefined ? null : currentUser.phone}
                  </div>
                </li>
                  <li className="details-item form-group">
                  <div className="details-label">{t("donorDetails.email")}</div>
                    <div className="details-value details-value__email">
                    {currentUser === undefined ? null : currentUser.email}
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label" />
                  <div className="details-value">
                    <Checkbox
                      label={t("donorDetails.becs")}
                      disabled={isUserPending || !isEditable}
                      checkboxHandler={checkboxHandler}
                      checked={isChecked}
                    />
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label" />
                  <div className="details-value">
                    <Checkbox
                      label={t("donorDetails.appuser")}
                      disabled={isUserPending}
                      checked={isAppUser}
                      isEditable={false}
                    />
                  </div>
                </li>
                <li className="details-item form-group">
                  <div className="details-label" />
                  <div className="details-value">
                    <Checkbox
                      label={t("donorDetails.sms")}
                      disabled={isUserPending || !isEditable}
                      checkboxHandler={smsCheckboxHandler}
                      checked={isSms}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </CustomScrollbar>
      )}
      <div className="modal-footer">
        <div className="inputs-group">
          <div className="inputs-group-item">
            <Button
              type="button"
              text={t("common.cancel")}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
          <div className="inputs-group-item">
            <Button
              btnClassName="btn-primary"
              type="button"
              text={t("common.save")}
              disabled={
                userRoleName !== "admin" ||
                isUserPending ||
                !isEditable ||
                !isChecked ||
                currentAppType === "" ||
                currentDonorStatus === "" ||
                (currentDonorStatus !== DONOR_STATUS_DETAILS[0].value &&
                  (currentBloodType === "" || currentRhesus === ""))
              }
              onClick={() => {
                if (isChecked) {
                  const userId =
                    currentUser.id === undefined || currentUser.id === null
                      ? currentDetails.userId
                      : currentUser.id;

                  const updatedCurrentUser = { ...currentUser }; // for changing
                  delete updatedCurrentUser.bloodTiter; // for changing
                  updateDonor({
                    ...updatedCurrentUser,
                    id: userId,
                    ...(currentBloodType &&
                      currentRhesus && {
                      donorType: {
                        bloodType: currentBloodType,
                        rhesus: currentRhesus
                      }
                    }),
                    status: currentDonorStatus,
                    type: currentAppType,
                    registeredInBecs: true,
                    quarantineDesc: currentQuarantine,
                    ...(currentQuarantineExpire && {
                      quarantine: Date.parse(currentQuarantineExpire)
                    }),
                    ...(currentLastDonation && {
                      lastDonation: Date.parse(currentLastDonation)
                    }),
                    reservationId:
                      currentDetails && currentDetails.reservationId
                        ? currentDetails.reservationId
                        : "",
                    appointmentId:
                      currentDetails && currentDetails.appointmentId
                        ? currentDetails.appointmentId
                        : "",
                    titer: currentBloodTiter, // for changing
                    sms: isSms
                  });
                  handleClose();
                }
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DonorDetails;
